
import erc20Abi from './abis/erc20.json';
import arsAbi from './abis/arsToken.json';
import aTokenAbi from './abis/aToken.json';
import aEthAbi from './abis/aEth.json';
import comptrollerAbi from './abis/comptroller.json';
import priceOracleAbi from './abis/priceOracle.json';
import voteAbi from './abis/vote.json';
import interestModelAbi from './abis/interestModel.json';
import stakingAbi from './abis/staking.json';
import referralAbi from './abis/referral.json';
import multiFeeDistribution from './abis/multiFeeDistribution.json';
import chefIncentivesController from './abis/chefIncentivesController.json';
import lockZap from './abis/lockZap.json';
import lockZap1 from './abis/lockZap1.json';
import liquidityZap from './abis/liquidityZap.json';
import eligibilityDataProvider from './abis/eligibilityDataProvider.json';
import compounder from './abis/compounder.json';
import balancerPoolHelper from './abis/balancerPoolHelper.json';
import config from '../config';

// export const GAS_PRICE = {
//     1116: 33000000000,
//     1115: 33000000000,
//     42161: 3000000000,
//     421614: 3000000000
// };
export const LIQUIDITY_CLOSE_FACTOR = 0.5;
export const LIQUIDITY_INCENTIVE = 1.1;

export const WEB3_PROVIDER = {
    1116: config.coreWeb3Provider,
    1115: config.coreWeb3TestProvider,
    42161: config.arbitrumWeb3Provider,
    421614: config.arbitrumWeb3TestProvider
}

export const ERC20_TOKEN_ABI = erc20Abi;
export const CONTRACT_ARS_TOKEN_ABI = arsAbi;

export const CONTRACT_TOKEN_ADDRESS_TEMP = {
    1116: {
        wcore: {
            id: 'wcore',
            symbol: 'WCORE',
            address: '0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f',
            decimals: 18
        },
    },
    1115: {
        wcore: {
            id: 'wcore',
            symbol: 'WCORE',
            address: '0x5EB2bB0e68e05b04228ec1dD9003529cc0C7c0d0',
            decimals: 18
        },
    },
    421614: {
        weth: {
            id: 'weth',
            symbol: 'WETH',
            address: '0x190fb31ec60386645BFfdda674c1C51860B78f2d',
            decimals: 18
        },
    },
    42161: {
        weth: {
            id: 'weth',
            symbol: 'WETH',
            address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
            decimals: 18
        },
    }
}

export const CONTRACT_TOKEN_ADDRESS = {
    1116: {
        usdc: {
            id: 'usdc',
            symbol: 'USDC',
            address: '0xa4151B2B3e269645181dCcF2D426cE75fcbDeca9',
            decimals: 6
        },
        usdt: {
            id: 'usdt',
            symbol: 'USDT',
            address: '0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1',
            decimals: 6
        },
        weth: {
            id: 'weth',
            symbol: 'WETH',
            address: '0xeAB3aC417c4d6dF6b143346a46fEe1B847B50296',
            decimals: 18
        },
        core: {
            id: 'core',
            symbol: 'CORE',
            decimals: 18
        },
        ars: {
            id: 'ars',
            symbol: 'ARS',
            address: '0x204e2D49b7cDA6d93301bcF667A2Da28Fb0e5780',
            decimals: 18
        },
    },
    1115: {
        usdc: {
            id: 'usdc',
            symbol: 'USDC',
            address: '0x0C603197D655170bF0EF9b9B5401A0DFb25E971a',
            decimals: 6
        },
        usdt: {
            id: 'usdt',
            symbol: 'USDT',
            address: '0xeEf77B4FF53AC630502CcEf34A348127f775cdfc',
            decimals: 6
        },
        weth: {
            id: 'weth',
            symbol: 'WETH',
            address: '0xf98cd362bC67Fc240f3839eCC5120627Ab505a5b',
            decimals: 18
        },
        core: {
            id: 'core',
            symbol: 'CORE',
            decimals: 18
        },
        ars: {
            id: 'ars',
            symbol: 'ARS',
            address: '0xFD78B570781DdA125195FBEc4E063d8252a3d30D',
            decimals: 18
        },
    },
    421614: {
        usdt: {
            id: 'usdt',
            symbol: 'USDT',
            address: '0xD446b6Dc05A0BD0DbE4018800159ef61Bd6f5C5b',
            decimals: 6
        },
        usdc: {
            id: 'usdc',
            symbol: 'USDC',
            address: '0x4244a74C7262a7787Ff338cC8200672bc6d7d830',
            decimals: 6
        },
        'usdc.e': {
            id: 'usdc.e',
            symbol: 'USDC.e',
            address: '0x56D225a420c06cef8a4CD4d392a6c8E4b2bC8EA9',
            decimals: 6
        },
        wbtc: {
            id: 'wbtc',
            symbol: 'WBTC',
            address: '0x3159f654100f4220eBE88c76EcAfce9189B341e2',
            decimals: 8
        },
        dai: {
            id: 'dai',
            symbol: 'DAI',
            address: '0x9F3d9Bd044323c011C8292055de4530aF211E172',
            decimals: 18
        },
        arb: {
            id: 'arb',
            symbol: 'ARB',
            address: '0xFBE2467593475bCfBF36b45F5c47D56DD4568121',
            decimals: 18
        },
        eth: {
            id: 'eth',
            symbol: 'ETH',
            decimals: 18
        },
        ars: {
            id: 'ars',
            symbol: 'ARS',
            address: '0x64A0bE248cD69F1106B180c65adC385d62741A84',
            decimals: 18
        },
        wsteth: {
            id: 'wsteth',
            symbol: 'wstETH',
            address: '0x57510005e4183d080c3Fd5b643f9EE3356e49fBe',
            decimals: 18
        },
        gmx: {
            id: 'gmx',
            symbol: 'GMX',
            address: '0x7D7ddbebE1fb33b7680265858aCc779D84Ca1659',
            decimals: 18
        },
    },
    42161: {
        usdt: {
            id: 'usdt',
            symbol: 'USDT',
            address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
            decimals: 6
        },
        usdc: {
            id: 'usdc',
            symbol: 'USDC',
            address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
            decimals: 6
        },
        'usdc.e': {
            id: 'usdc.e',
            symbol: 'USDC.e',
            address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
            decimals: 6
        },
        wbtc: {
            id: 'wbtc',
            symbol: 'WBTC',
            address: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
            decimals: 8
        },
        dai: {
            id: 'dai',
            symbol: 'DAI',
            address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
            decimals: 18
        },
        arb: {
            id: 'arb',
            symbol: 'ARB',
            address: '0x912CE59144191C1204E64559FE8253a0e49E6548',
            decimals: 18
        },
        eth: {
            id: 'eth',
            symbol: 'ETH',
            decimals: 18
        },
        ars: {
            id: 'ars',
            symbol: 'ARS',
            address: '0x2EADCE7EBDEDF3c7291F1f658208A2720Ff4B74d',
            decimals: 18
        },
        wsteth: {
            id: 'wsteth',
            symbol: 'wstETH',
            address: '0x5979D7b546E38E414F7E9822514be443A4800529',
            decimals: 18
        },
        gmx: {
            id: 'gmx',
            symbol: 'GMX',
            address: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
            decimals: 18
        },
    }
};

export const CONTRACT_AETH_ABI = aEthAbi;
export const CONTRACT_ABEP_ABI = aTokenAbi;
export const CONTRACT_ABEP_ADDRESS = {
    1116: {
        usdt: {
            id: 'usdt',
            symbol: 'aUSDT',
            address: '0xB6DD662F55375d809500B4A7CeAf20CB6DAFb05e'
        },
        usdc: {
            id: 'usdc',
            symbol: 'aUSDC',
            address: '0xe981FbD1e8815eb5074110e48fA1621D490d363C'
        },
        weth: {
            id: 'weth',
            symbol: 'aWETH',
            address: '0x87bf3aB1C752a4B12Fa114E10732d00D62e6519f'
        },
        core: {
            id: 'core',
            symbol: 'aCORE',
            address: '0x03ef96F537A7CDa4411C8643afD9d8814D5B4906'
        },
    },
    1115: {
        usdt: {
            id: 'usdt',
            symbol: 'aUSDT',
            address: '0x0261E75f28FAe1CcAb36534A1F1F050D86a7fA4A'
        },
        usdc: {
            id: 'usdc',
            symbol: 'aUSDC',
            address: '0x2a48F612A96569732D5c72e46292157cfE942F71'
        },
        weth: {
            id: 'weth',
            symbol: 'aWETH',
            address: '0xABF0c03d1793ab5924aF7055a8d3284adfB92c0e'
        },
        core: {
            id: 'core',
            symbol: 'aCORE',
            address: '0xCf0E49800bD0Fe4bbddf220C3766268359049678'
        },
    },
    421614: {
        usdt: {
            id: 'usdt',
            symbol: 'aUSDT',
            address: '0x9876369388F26939b7b9c9A93f07c5DA1085f029'
        },
        usdc: {
            id: 'usdc',
            symbol: 'aUSDC',
            address: '0xF9Bd9e4ff5519B5fA880Ccf09925D65156d3c50f'
        },
        'usdc.e': {
            id: 'usdc.e',
            symbol: 'aUSDC.e',
            address: '0x5F1617CaEf5270bFdDe3028327573974C649D874'
        },
        wbtc: {
            id: 'wbtc',
            symbol: 'aWBTC',
            address: '0xE0A1079605da29D2DFC943624ad08552BB4749B7'
        },
        dai: {
            id: 'dai',
            symbol: 'aDAI',
            address: '0x7E08DD3D097bEB2a78bD3F87Ae005DE749dd286d'
        },
        arb: {
            id: 'arb',
            symbol: 'aARB',
            address: '0x6bb72223639f969c5949d8aa9BD7F84aD4C16961'
        },
        eth: {
            id: 'weth',
            symbol: 'aWETH',
            address: '0x712e189fE16172F09C59D6c95be38a8662D3c96a'
        },
        wsteth: {
            id: 'wsteth',
            symbol: 'aWSTETH',
            address: '0xc2097CE68166eB53C533311a4CdDE3319d544c08',
        },
        gmx: {
            id: 'gmx',
            symbol: 'aGMX',
            address: '0x08603521752Cebae7d7c3DDFbEbE9FeF3748b75f',
        },
    },
    42161: {
        usdt: {
            id: 'usdt',
            symbol: 'aUSDT',
            address: '0xDC8f714d9a5c67266d6da2E5d3267d1587Ea5710'
        },
        usdc: {
            id: 'usdc',
            symbol: 'aUSDC',
            address: '0xC4c660Db66C1D3f1D13144Fb8E5bEFC6ad2892a9'
        },
        'usdc.e': {
            id: 'usdc.e',
            symbol: 'aUSDC.e',
            address: '0xb1f1c02Bda47062ec30480831D0Ff468Fa9C37e1'
        },
        wbtc: {
            id: 'wbtc',
            symbol: 'aWBTC',
            address: '0xEAC09CB245988a0805ab61beeDc78c1c848c15F9'
        },
        dai: {
            id: 'dai',
            symbol: 'aDAI',
            address: '0x25CA01DaefC58b039A5aD97243d6FfE6899710Fc'
        },
        arb: {
            id: 'arb',
            symbol: 'aARB',
            address: '0x03ef96F537A7CDa4411C8643afD9d8814D5B4906'
        },
        eth: {
            id: 'weth',
            symbol: 'aWETH',
            address: '0x13F9A7F33550FE935BF7B544E536584d30eCf50a'
        },
        wsteth: {
            id: 'wsteth',
            symbol: 'aWSTETH',
            address: '0x187AbAD5aAEA813c7668A279D4ddA55a908bB27A',
        },
        gmx: {
            id: 'gmx',
            symbol: 'aGMX',
            address: '0xA588AAc732a7Cd96519CcaEBf48a9e19D5E6dD01',
        },
    }
};

export const CONTRACT_COMPTROLLER_ABI = comptrollerAbi;
export const CONTRACT_COMPTROLLER_ADDRESS = {
    1116: '0x6056Eb6a5634468647B8cB892d3DaA5F816939FC',
    1115: '0x57750F1C8a95c17976f57b061DCa956B42bf4CB1',
    421614: '0xe5b7761b214D40aFEE9D21Ee40C708a0850fb38b',
    42161: '0xf854C6b57E6d8Ec3E790eC6Da1a13060d19549d4',
}

export const CONTRACT_PRICE_ORACLE_ABI = priceOracleAbi;
export const CONTRACT_PRICE_ORACLE_ADDRESS = {
    1116: '0x4c4aBE259b52CDD4C7F0108E95b33415c2981e88',
    1115: '0x7FaB9a1a6A78427d2193B051e671968589f5D58e',
}

export const CONTRACT_VOTE_ABI = voteAbi;
export const CONTRACT_VOTE_ADDRESS = {
    1116: '0xCa77eed381Df410F24E92Dc945f5f7277FB04Fd3',
    1115: '0xF5bf1A2F4EfDcE2fb53a5744C60231BdAD838063',
    421614: '0xE3C9d87a9e52Df74E78a4f8a5C5d592edBfeb892',
    42161: '0xEdD8178707ddBF33432bC4fd3CFE504c79EEF564',
}

export const CONTRACT_INTEREST_MODEL_ABI = interestModelAbi;

export const MULTICALL_ADDRESS = {
    1116: '0xE802777Aa2135e6D9f3b2b55e11416E9a0DCfd4c',
    1115: '0x0Cd25Be41D6f938018a2bB21b87dA3d0887e9Ce5',
    421614: '0x4A4d28fF6c45b4709771fbaE8774C45975d7D5ea',
    42161: '0xdDd26973059093ef5080bE9e0F151f8998101a0D',
}

export const STAKING_ABI = stakingAbi;
export const STAKING_ADDRESS = {
    1116: '0x959C7898318DC3c8fD11cbC5000f4e36F75144EC',
    1115: '0x02EF7eaf43Befd8c82A107aA945C27d3a487229A',
}

export const REFERRAL_ABI = referralAbi;
export const REFERRAL_ADDRESS = {
    1116: '0x60DDb55E167ABbD50F829C84E80E043197bcC0eC',
    1115: '0x0895ddf4821aDcF0170d301FB3887db9D39d2E9C',
    421614: '0xba032bc8952d700E6FbED0A2bA95c0a04F500ED3',
    42161: '0x4DeBAEBB70088d4F83219110D8E87Bdb8467D395',
}

export const MULTI_FEE_DISTRIBUTION_ABI = multiFeeDistribution;
export const MULTI_FEE_DISTRIBUTION = {
    1116: '0x12Ea34F0fbb89953813c26e7a2EB651fE6D5dA9B',
    1115: '0x088f8628c00ABDe774201B1Ba107D83c66Dbe0be',
    421614: '0xd3F6346e0c829c7b2196c359b754f5013e0edb46',
    42161: '0x82f3886EE8c60f86436B51e600bD9E3d04B2C373',
}

export const DLP_ADDRESS = {
    1116: '0xeaf1a065f85cf02547002d26aa42ee4516e21aa1',
    1115: '0x116036a4B32c431FF7116E64d22499C641dF65Fa',
    421614: '0xB38beE5f8120d63070EAf9D82eA3E31769DAa088',
    42161: '0x05f0a172608853d5431C69888A0a559886a3319f',
}

export const CHEF_INCENTIVES_CONTROLLER_ABI = chefIncentivesController;
export const CHEF_INCENTIVES_CONTROLLER = {
    1116: '0xDf975BE74fF307126048c9f06605a620317893Ba',
    1115: '0x577d3998DA9a3b43fDe25cd97bd936C402e9a706',
    421614: '0x1AF85149be76a9b9B3E33a7b23D83e788807e160',
    42161: '0xb26EC11Cd1908a3D3dc0d9837daa9bB8f3eED54F',
}

export const LOCK_ZAP_ABI = {
    1116: lockZap,
    1115: lockZap,
    421614: lockZap1,
    42161: lockZap1,
}
export const LOCK_ZAP = {
    1116: '0xE7B5638161C88088C1f318530C258FC269D87d50',
    1115: '0xB519929d29B8b0559794Dd6cf9618fF486d26785',
    421614: '0x99DB304A8acfEF887BE66D26867e4Efcc49A0369',
    42161: '0x3Cd4F7e202898cf6E893fA7e9b9abb46616fA90C',
}

export const LIQUIDITY_ZAP_ABI = liquidityZap;
export const LIQUIDITY_ZAP = {
    1116: '0xb34F1115819D68979568Fc4705aE048E66456323',
    1115: '0x70c0ed4c55453cD9c650D68330e9AA268bf28C87',
    421614: '0x4a3A310E471683FA27D94F97d343Ce4244D930C5',
    42161: '0x5d8D0e1225A2d8B410600fDB98e4A916370Efd7E',
}

export const ELIGI_DATA_PROVIDER_ABI = eligibilityDataProvider;
export const ELIGI_DATA_PROVIDER = {
    1116: '0x454d8042BF4fb4B6F5bE22f400383B4c5ffC19Ff',
    1115: '0xF73D530294aEE4aCCF3Dd70415A4FC48D1027094',
    421614: '0xdd89a003A72481718F99287c8E3A5b2C8daAbeE5',
    42161: '0x7fEf851AaBD1266B1523165DdE9883A5aEF08d94',
}

export const COMPOUNDER_ABI = compounder;
export const COMPOUNDER = {
    1116: '0x0396d5b660582d25707827351FBeE175992210Db',
    1115: '0xA551ACC1E782d53422C66647adA663Dc3E6C3bad',
    421614: '0x3D56849b1DF19Acb76E72F211741de426E0EC1a4',
    42161: '0xB6DD662F55375d809500B4A7CeAf20CB6DAFb05e',
}

export const BALANCER_POOL_HELPER_ABI = balancerPoolHelper
export const BALANCER_POOL_HELPER = {
    421614: '0xeC932b94B5fE989718633AA79e61Ba412134B2C1',
    42161: '0x1EF637121648b4E1Ed49edBBF9f9C4D80f08a90B',
}