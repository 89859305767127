import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import Loading from 'components/UI/Loading';
import { useEffect, useState } from 'react';
import { useActiveWeb3React } from 'hooks';
import { restService } from 'utilities';
import { NotificationManager } from 'react-notifications';

const StyledContainer = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px;
  border-radius: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 128px;
  @media (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
  }
  .title-container {
    display: flex;
    flex-direction: column;
    gap: 9px;
    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      color: #FFFFFF99;
    }
  }
  .input-container {
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 16px;
    border-radius: 4px;
    border: 1px solid #FFFFFF33;
    background: #00000033;
    gap: 10px;
    @media (max-width: 992px) {
    }
    input {
      flex: 1;
      background: transparent;
      border: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      &:focus {
        outline: none;
      }
      ::placeholder {
        padding: 0 !important;
        color: #9CA3AF !important;
      }
      @media (max-width: 992px) {
      }
    }
    .subscribe-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px 10px 20px;
      border-radius: 4px;
      border: 1px solid #0EA5E9;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      cursor: pointer;
      width: 150px;
      @media (max-width: 992px) {
        width: 120px;
        padding: 10px 10px 10px 10px;
      }
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
  }
`;

function Subscribe() {
  const { t } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();

  const [needToSubscribe, setNeedToSubscribe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const response = await restService({
        chainId: requiredChainId,
        api: `/v1/subscribe/email?address=${account}`,
        method: 'GET',
      });
      setNeedToSubscribe(!response?.data?.data?.enable);
    }
    if (account) {
      fetchData();
    } else {
      setNeedToSubscribe(false);
    }
  }, [account]);

  const handleSubscrible = async () => {
    if (email) {
      setLoading(true);
      const response = await restService({
        chainId: requiredChainId,
        api: `/v1/subscribe/email?address=${account}`,
        method: 'POST',
        params: {
          address: account,
          email,
        }
      });
      if (response?.data) {
        if(!response?.data?.status) {
          NotificationManager.error(t("Invalid_Email"));
        } else {
          NotificationManager.success(t("Subscribed_successfully"));
          setNeedToSubscribe(false);
        }
      }
      setLoading(false);
    }
  }

  if (!account || !needToSubscribe) {
    return null;
  }

  return (
    <StyledContainer className='w-full'>
      <div className='title-container'>
        <div className='title'>
          {t("Subscribe_title")}
        </div>
        <div className='desc'>
          {t("Subscribe_desc")}
        </div>
      </div>
      <div className='flex items-center'>
        <div className='input-container w-full'>
          <input
            placeholder={t("Enter_your_email")}
            value={email}
            type='email'
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="subscribe-button"
            type="button"
            disabled={loading || !email}
            onClick={handleSubscrible}
          >
            {loading && <Loading size={'14px'} margin={'7px'} />}
            {t('Subscribe')}
          </button>
        </div>
      </div>
    </StyledContainer>
  )
}

export default Subscribe;